import React from "react";
import loadable from "@loadable/component";
import { Section } from "@atoms";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const Frame = loadable(() => import("@templates/Frame"));
const HeroImage = loadable(() => import("@organisms/HeroImage"));
const GrowingImageCopy = loadable(() => import("@templates/GrowingImageCopy"));
const GrowingImageSlide = loadable(() =>
  import("@templates/GrowingImageSlide")
);
const Grants = loadable(() => import("@templates/Grants"));
const GrowingImage = loadable(() => import("@organisms/GrowingImage"));
const StoriesOfChangeScroll = loadable(() =>
  import("@templates/StoriesOfChangeScroll")
);
const SectionIntro = loadable(() => import("@organisms/SectionIntro"));
const Audiogram = loadable(() => import("@organisms/Audiogram"));

const DreamPage = ({ data, ...props }) => {
  const {
    title,
    intro,
    grantmaking,
    stats,
    map,
    community,
    moving,
    investment,
    returnInvestment,
    prologue,
    stories,
  } = data;

  return (
    <DefaultPageContainer {...props}>
      <Frame title={intro?.heading || title} next="/we-moved-ideas" />
      <HeroImage {...intro} />
      <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
      {/* grantmaking */}
      <Section anchor="grantmaking">
        <GrowingImageCopy {...grantmaking} />
        <Grants world={stats} map={map} />
      </Section>
      {/* stories */}
      <Section anchor="community">
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...community} headingAlign="center" />
      </Section>
      {/* moving in crisis */}
      <Section anchor="moving">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...moving} headingAlign="center" />
      </Section>
      {/* prologue */}
      <Section anchor="stories">
        <GrowingImage {...prologue} headingAlign="center" />
        <StoriesOfChangeScroll stories={stories} />
      </Section>
      {/* investment */}
      <Section anchor="investment">
        <div className="relative z-10 mb-12 border-b-3 border-white md:ml-14" />
        <SectionIntro {...investment} bottomIcon />
        {/* <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" /> */}
        <Audiogram {...returnInvestment.audiogram} />
        {/* <GrowingImageSlide {...returnInvestment} headingAlign="center" /> */}
      </Section>
    </DefaultPageContainer>
  );
};

DreamPage.defaultProps = {};

export default DreamPage;
