import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/MovedMoney";
import resolveSideScroll from "@dataResolvers/resolveSideScroll";
import resolveStory from "@dataResolvers/resolveStory";
import resolveImage from "@dataResolvers/resolveImage";
import resolveAudiogram from "@dataResolvers/resolveAudiogram";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query WeMovedMoneyQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "weMovedMoney", siteId: $siteId) {
        ... on Craft_weMovedMoney_weMovedMoney_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # intro
          introHeading: heading0
          introDescriptor: descriptor0
          introImage: image0 {
            ...ImageFragment
          }
          # feminist grantmaking
          femGrantmakingHeading: heading1
          femGrantmakingDescription: descriptor1
          femGrantmakingCopy: copy0
          femGrantmakingImage: image1 {
            ...ImageFragment
          }
          femGrantmakingStats: stats {
            ... on Craft_stats_labelStatLabel_BlockType {
              type: typeHandle
              uid
              prefixLabel
              stat
              suffixLabel
            }
            ... on Craft_stats_statLabel_BlockType {
              type: typeHandle
              uid
              stat
              label
            }
            ... on Craft_stats_shortStats_BlockType {
              type: typeHandle
              uid
              stats {
                stat
                label
              }
            }
          }
          # map
          mapLabel: label0
          mapHeading: heading2
          mapSubheading: descriptor2
          mapCopy: copy1
          # growing community
          communityHeading: heading3
          communityScroll: sideScrollEmbed0 {
            ...SideScrollFragment
          }
          # stories of change
          storiesHeading: heading4
          storiesImage: image2 {
            ...ImageFragment
          }
          # stories prologue
          prologueHeading: heading8
          prologueCopy: copy2
          prologueStories: storiesList {
            ...StoriesFragment
          }
          # moving in crisis
          movingHeading: heading5
          movingScroll: sideScrollEmbed1 {
            ...SideScrollFragment
          }
          # investment portfolio
          investmentLabel: label1
          investmentHeading: heading6
          investmentDescription: descriptor3
          investmentCopy: copy3
          # investment return
          returnHeading: heading7
          returnAudiogram: audiogram {
            ...AudiogramFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    // intro,
    introHeading,
    introDescriptor,
    introImage,
    // fem grantmaking
    femGrantmakingHeading,
    femGrantmakingDescription,
    femGrantmakingCopy,
    femGrantmakingImage,
    // stats
    femGrantmakingStats,
    // map
    mapLabel,
    mapHeading,
    mapSubheading,
    mapCopy,
    // community
    communityHeading,
    communityScroll,
    // stories of change
    storiesHeading,
    storiesImage,
    // stories prologue
    prologueHeading,
    prologueCopy,
    prologueStories,
    // moving in crisis
    movingHeading,
    movingScroll,
    // investment
    investmentLabel,
    investmentHeading,
    investmentDescription,
    investmentCopy,
    // return
    returnHeading,
    returnAudiogram,
  } = entry;

  return {
    meta: {
      title,
    },
    title,
    intro: {
      heading: introHeading,
      description: introDescriptor,
      image: resolveImage(introImage),
    },
    grantmaking: {
      heading: femGrantmakingHeading,
      subheading: femGrantmakingDescription,
      copy: femGrantmakingCopy,
      image: resolveImage(femGrantmakingImage),
    },
    stats: {
      stats: femGrantmakingStats,
    },
    map: {
      label: mapLabel,
      heading: mapHeading,
      subheading: mapSubheading,
      copy: mapCopy,
    },
    community: {
      heading: communityHeading,
      sideScroll: resolveSideScroll(communityScroll),
    },
    prologue: {
      heading: storiesHeading,
      image: resolveImage(storiesImage),
    },
    stories: {
      heading: prologueHeading,
      copy: prologueCopy,
      stories: prologueStories.map(resolveStory),
    },
    moving: {
      heading: movingHeading,
      sideScroll: resolveSideScroll(movingScroll),
    },
    investment: {
      label: investmentLabel,
      heading: investmentHeading,
      description: investmentDescription,
      copy: investmentCopy,
    },
    returnInvestment: {
      heading: returnHeading,
      audiogram: resolveAudiogram(returnAudiogram),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="WeMovedMoneyQuery"
    {...props}
  />
);

export default Page;
